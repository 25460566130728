










import { Component, Vue } from 'vue-property-decorator'

@Component
export default class List extends Vue {
  private loading = false
  private tableData = []

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.monitor.monitor)
      .then((res) => {
        this.tableData = res
      })
      .finally(() => {
        this.loading = false
      })
  }
}
